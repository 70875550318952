import React, { Component } from "react";
import { RichText } from "prismic-reactjs";
//import { withPreview } from "gatsby-source-prismic-graphql";
import get from "lodash/get";
import { graphql, Link } from "gatsby";
import Layout from "@components/layout";
// import Image from "../components/image";
// import SEO from "../components/seo";
import { TwitterTimelineEmbed } from "react-twitter-embed";
// import Instafeed from "instafeed.js";
import LinkUtils from "@utilities/link-resolver";

import Classset from "react-classset";

import "@scss/home.scss";

export const homepageQuery = graphql`
  query HomepageQuery {
    allPrismicHomepage {
      nodes {
        data {
          title {
            html
            text
            raw
          }
          top_promo_image {
            url
            thumbnails {
              Mobile {
                url
              }
            }
          }
          top_promo_type
          top_promo_headline {
            html
            text
            raw
          }
          top_promo_description {
            html
            text
            raw
          }
          top_promo_call_to_action_text
          top_promo_background_color
          top_promo_background_color_opacity
          top_promo_call_to_action {
            link_type
            type
            uid
            url
          }
          sponsor_section_title
          sponsors {
            title
            logo {
              url
            }
          }
          homepage_intro {
            text
          }
          homepage_content {
            html
            text
            raw
          }
          promos {
            promo_type
            promo_headline
            promo_description {
              html
              text
              raw
            }
            promo_image {
              url
            }
            promo_background_color
            promo_text_color
            promo_call_to_action_text
            promo_call_to_action {
              link_type
              type
              uid
              url
            }
          }
        }
      }
    }
  }
`;

const Homepage = (props) => {
  const data = props.data;

  const currentPage = get(data, "allPrismicHomepage.nodes[0]");

  console.log(currentPage);

  const headerImage = get(currentPage, "data.top_promo_image");
  const promos = get(currentPage, "data.promos", []);
  const headerType = get(currentPage, "data.top_promo_type");

  const topPromoClasses = Classset({
    "top-promo": true,
    "top-promo--left": headerType === "Left",
    "top-promo--bottom": headerType === "Bottom",
    "top-promo--right": headerType === "Right",
    "top-promo--top": headerType === "Top",
  });

  const topPromoBgOpacity = get(
    currentPage,
    "data.top_promo_background_color_opacity",
    "30%"
  ).replace("%", "");
  const topPromoBgOpacityVal = parseInt(topPromoBgOpacity) / 100;
  const topPromoBgStyles = {
    opacity: topPromoBgOpacityVal,
    backgroundColor: get(
      currentPage,
      "data.top_promo_background_color",
      "transparent"
    ),
  };

  return (
    <Layout>
      <div id="homepage">
        <section className={topPromoClasses}>
          <div className="top-promo__copy">
            <div className="top-promo__copy--inner">
              <h2>
                {RichText.asText(currentPage.data.top_promo_headline.raw)}
              </h2>
              <div>
                {RichText.render(currentPage.data.top_promo_description.raw)}
              </div>
              <CallToAction
                callToAction={currentPage.data.top_promo_call_to_action}
                callToActionText={
                  currentPage.data.top_promo_call_to_action_text
                }
              />
            </div>
            <div
              className="top-promo__background-image"
              style={topPromoBgStyles}
            />
          </div>
          {headerImage && (
            <picture>
              {headerImage.thumbnails.Mobile && (
                <source
                  media="(max-width: 700px)"
                  srcSet={headerImage.thumbnails.Mobile.url}
                />
              )}
              <source srcSet={headerImage.url} />
              <img src={headerImage.url} alt={headerImage.alt} />
            </picture>
          )}
        </section>

        <section>
          <div className="home-intro">
            {RichText.render(currentPage.data.homepage_intro.raw)}
          </div>
        </section>

        <section>
          <div className="home-content">
            {RichText.render(currentPage.data.homepage_content.raw)}
          </div>
        </section>

        {promos &&
          promos.map((promo, index) => {
            const promoImage = get(promo, "promo_image", false);
            const promoType = get(promo, "promo_type");
            const promoHeadline = get(promo, "promo_headline");
            const promoCopy = get(promo, "promo_description");
            const promoClasses = Classset({
              promo: true,
              "promo--left": promoType === "Image Left Text Right",
              "promo--right": promoType === "Image Right Text Left",
              "promo--below": promoType === "Image Wide Text Below",
            });
            const promoTextColor = get(promo, "promo_text_color", "#000");
            const promoStyles = {
              color: promoTextColor,
              backgroundColor: get(promo, "promo_background_color", "#000"),
            };
            const image = promoImage ? (
              <div className="promo__image">
                <img
                  className="promo__image--img"
                  src={promo.promo_image.url}
                />
              </div>
            ) : null;
            const copy = promoHeadline ? (
              <div className="promo__copy">
                <h2>{promo.promo_headline}</h2>
                {promoCopy && <div>{RichText.render(promoCopy.raw)}</div>}
                <CallToAction
                  callToAction={promo.promo_call_to_action}
                  callToActionText={promo.promo_call_to_action_text}
                  style={{ borderColor: promoTextColor }}
                />
              </div>
            ) : null;
            let inner;
            switch (promoType) {
              case "Image Left Text Right":
                inner = (
                  <div className="promo__inner clearfix">
                    {image}
                    {copy}
                  </div>
                );
                break;
              case "Image Right Text Left":
                inner = (
                  <div className="promo__inner clearfix">
                    {copy}
                    {image}
                  </div>
                );
                break;
              case "Image Wide Text Below":
                inner = (
                  <div className="promo__inner clearfix">
                    {image}
                    {copy}
                  </div>
                );
                break;
              default:
                inner = (
                  <div className="promo__inner clearfix">
                    {copy}
                    {image}
                  </div>
                );
            }
            return (
              <section key={index} style={promoStyles} className={promoClasses}>
                {inner}
              </section>
            );
          })}

        {currentPage.data.sponsors && (
          <section className="sponsors">
            <h2>
              <span>{currentPage.data.sponsor_section_title}</span>
            </h2>
            <ul className="sponsors__list">
              {currentPage.data.sponsors.map((sponsor, index) => {
                const sponsorStyle = {
                  backgroundImage: `url(${sponsor.logo.url})`,
                };
                return (
                  <li
                    key={index}
                    className="sponsors__list-item"
                    title={sponsor.title}
                    style={sponsorStyle}
                  />
                );
              })}
            </ul>
          </section>
        )}
        <section className="social clearfix">
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="FutureOf_Cities"
            theme="dark"
            options={{ height: 700 }}
          />
        </section>
      </div>
    </Layout>
  );
};

class CallToAction extends Component {
  render() {
    const { callToAction, callToActionText } = this.props;

    console.log(callToAction);
    const callToActionExternal =
      get(callToAction, "__typename") === "Web" ? true : false;

    return (
      <>
        {callToActionExternal ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={callToAction.url}
            style={this.props.style}
          >
            {callToActionText}
          </a>
        ) : (
          <>
            {callToAction && (
              <Link
                to={LinkUtils.getUrlFromLink(callToAction)}
                style={this.props.style}
              >
                {callToActionText}
              </Link>
            )}
          </>
        )}
      </>
    );
  }
}

export default Homepage;
